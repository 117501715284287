import React, {Component} from 'react';

class Profile extends Component {

    render() {
        return (
            <p>Profile</p>
        )
    }
}

export default Profile;